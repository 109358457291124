import React from "react";
import "../css/BreadCrumb.css";
import Traduccio from "../Traduccio";
import { Link } from "react-router-dom";
import { useHistory, useLocation } from "react-router";

function BreadCrumb(props) {
  let location = useLocation();
  const history = useHistory();

  return (
    <nav aria-label="breadcrumb" className="bread">
      <ol className="breadcrumb">
        <li className="breadcrumb-item">
          <Link
            to="/"
            className={`${location.pathname === "/" ? "" : "active"}`}
          >
            <Traduccio string="nav.inici" />
          </Link>
        </li>
        {location.pathname === "/" ? (
          ""
        ) : props.titolFitxa?.subtitol ? (
          <li className={`breadcrumb-item`} aria-current="page">
            <Link className={`active`}  to="#" onClick={() => props.titolFitxa.href ? history.push(props.titolFitxa.href) : history.goBack()}>
              {props.titolFitxa.titol}
            </Link>
          </li>
        ) : (
          <li className={`breadcrumb-item`} aria-current="page">
            {props.titolFitxa?.titol}
          </li>
        )}

        {props.titolFitxa?.subtitol ? (
          <li className="breadcrumb-item active" aria-current="page">
            {props.titolFitxa.subtitol}
          </li>
        ) : (
          ""
        )}
      </ol>
    </nav>
    
  );
}

export default BreadCrumb;
