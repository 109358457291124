import React, { useEffect, useState } from "react";
import "./css/Fitxa.css";
import "./css/MapaWeb.css";
import Axios from "../Axios";
import { Link } from "react-router-dom";
import SpinnerLoading from "../Components/SubComponents/SpinnerLoading";

function MapaWeb(props) {
  const [dataMenus, setDataMenus] = useState([]);
  const [dataBanners, setDataBanners] = useState([]);
  const [dataPeu, setDataPeu] = useState([]);
  const [loading, setLoading] = useState(false);

  const [properties, setProperties] = useState([]);

  useEffect(() => {
    setLoading(true);
    const propietats = localStorage.getItem("propietats");
    setProperties(JSON.parse(propietats));

    Axios.get("/menusPortal", {
      params: {
        lang: localStorage.getItem("lang"),
      },
    })
      .then(function (response) {
        setDataMenus(response.data);
        setLoading(false);
      })
      .catch(function (error) {});

    Axios.get("/baners", {
      params: {
        lang: localStorage.getItem("lang"),
      },
    })
      .then(function (response) {
        setDataBanners(response.data);
        setLoading(false);
      })
      .catch(function (error) {});

    Axios.get("/peu", {
      params: {
        lang: localStorage.getItem("lang"),
      },
    })
      .then(function (response) {
        setDataPeu(response.data);
        setLoading(false);
      })
      .catch(function (error) {});
  }, []);

  return (
    <>
      {loading ? (
        <SpinnerLoading />
      ) : (
        <div className="container">
          <h1 className="titol text-center">{props.data.titol}</h1>

          {dataMenus?.map((menuTitol, categoryIndex) => {
            const linksMenu = menuTitol;

            switch (menuTitol.codi) {
              case properties.menuAreesAuditoriaPortalWeb:
                return (
                  <div key={categoryIndex}>
                    <p className="mt-3 labelProcediment">
                      <strong>{menuTitol.titol}</strong>
                    </p>
                    <div className="textProcediment">
                      <ul className="llistaMapa">
                        {linksMenu.submenuList.map((links, linksIndex) => (
                          <li key={linksIndex}>
                            <Link to={`/arees-auditoria/${links.codi}`}>
                              {links.titol}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                );
              case properties.menuNoticiesPortalWeb:
                return (
                  <div key={categoryIndex}>
                    <p className="mt-3 labelProcediment">
                      <strong>{menuTitol.titol}</strong>
                    </p>
                    <div className="textProcediment">
                      <ul className="llistaMapa">
                        {linksMenu.identificadorFitxes.map(
                          (links, linksIndex) => (
                            <li key={linksIndex}>
                              <Link to={`/noticies`}>{links.titol}</Link>
                            </li>
                          )
                        )}
                      </ul>
                    </div>
                  </div>
                );
              case properties.menuEnllassosInteresPortalWeb:
                return (
                  <div key={categoryIndex}>
                    <p className="mt-3 labelProcediment">
                      <strong>{menuTitol.titol}</strong>
                    </p>
                    <div className="textProcediment">
                      <ul className="llistaMapa">
                        {linksMenu.identificadorFitxes.map(
                          (links, linksIndex) => (
                            <li key={linksIndex}>
                              <Link to={`/enllassos`}>{links.titol}</Link>
                            </li>
                          )
                        )}
                      </ul>
                    </div>
                  </div>
                );

              default:
                return (
                  <div key={categoryIndex}>
                    <p className="mt-3 labelProcediment">
                      <strong>{menuTitol.titol}</strong>
                    </p>
                    <div className="textProcediment">
                      <ul className="llistaMapa">
                        {linksMenu.identificadorFitxes.map(
                          (links, linksIndex) => (
                            <li key={linksIndex}>
                              <Link to={`/fitxa/${links.codigo}`}>
                                {links.titol}
                              </Link>
                            </li>
                          )
                        )}
                      </ul>
                    </div>
                  </div>
                );
            }
          })}

          {dataBanners && (
            <>
              <p className="mt-3 labelProcediment">
                <strong>{dataBanners.titol}</strong>
              </p>
              <div className="textProcediment">
                <ul className="llistaMapa">
                  {dataBanners.baners?.map((bannerTitol, categoryIndex) => {
                    if (bannerTitol.url === null || bannerTitol.url === "") {
                      return (
                        <li key={categoryIndex}>
                          <Link to={`/fitxa/${bannerTitol.codigo}`}>
                            {bannerTitol.titol}
                          </Link>
                        </li>
                      );
                    } else {
                      return (
                        <li key={categoryIndex}>
                          <a href={bannerTitol.url} target="blank">
                            {bannerTitol.titol}
                          </a>
                        </li>
                      );
                    }
                  })}
                </ul>
              </div>
            </>
          )}

          {dataPeu && (
            <>
              <p className="mt-3 labelProcediment">
                <strong>{dataPeu.titol}</strong>
              </p>
              <div className="textProcediment">
                <ul className="llistaMapa">
                  {dataPeu.fitxesPeu?.map((peu, categoryIndex) => {
                    if (peu.url === null || peu.url === "") {
                      return (
                        <li key={categoryIndex}>
                          <Link to={`/fitxa/${peu.codigo}`}>{peu.titol}</Link>
                        </li>
                      );
                    } else {
                      return (
                        <li key={categoryIndex}>
                          <a href={peu.url} target="blank">
                            {peu.titol}
                          </a>
                        </li>
                      );
                    }
                  })}
                </ul>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
}

export default MapaWeb;
