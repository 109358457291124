import React, { Component } from "react";
import IdiomaContext from "../Context/IdiomaContext";

import ca from "../Traducciones/ca.json";
import es from "../Traducciones/es.json";

class Traduccio extends Component {
  idiomes = {
    ca,
    es,
  };

  render() {
    const { string } = this.props;

    return (
      <IdiomaContext.Consumer>
        {(value) => this.idiomes[value][string]}
      </IdiomaContext.Consumer>
    );
  }
}

window.Traduccio = Traduccio;

export default Traduccio;
