import axios from "axios";

const AxiosApi = axios.create();

const username = "admin";
const password = "admin";
const basicAuth = "Basic " + btoa(username + ":" + password);

const authToken = () =>
  "Bearer " +
  "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJpc3MiOiJjZWNvY2xvdWQiLCJhdWQiOiJhdXRoIiwic3ViIjoidXN1YXJpIiwiZXhwIjo5OTY2MzQ1MjQxMiwibmFtZSI6IlVzdWFyaSBBZG1pbiIsImVtYWlsIjoidXN1YXJpQGxpbWl0LmVzIiwicmV4cCI6OTk2NjM0NTI0MTIsInJvbCI6W119.9IUEk64QS78WCq_DJbFFwqPv1m52drGB4-RwcZEJNUd2I4MCry0Y0aqqRxsLO2xHPtZxl-YugCaYSzDnmY1-oA";

AxiosApi.defaults.baseURL = process.env.REACT_APP_TRANSPARENCIA_SERVER_API;


AxiosApi.interceptors.request.use(
  function (conf) {
    conf.headers = {
      Authorization: authToken(),
      "Content-Type": "application/json",
    };

    return conf;
  },
  function (error) {
    return Promise.reject(error);
  }
);

AxiosApi.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default AxiosApi;
