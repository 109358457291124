import React, { useState } from "react";
import "./css/Contacte.css";
import DocumentCallOut from "../Components/SubComponents/DocumentsCallOut";
import LinksCallOut from "../Components/SubComponents/LinksCallOut";
import VideoYoutube from "../Components/SubComponents/VideoYoutube";
import Traduccio from "../Components/Traduccio";
import SpinnerLoading from "../Components/SubComponents/SpinnerLoading";
import { Send, Asterisk, Envelope } from "react-bootstrap-icons";
import Axios from "../Axios";
import ReCAPTCHA from "react-google-recaptcha";

function Contacte(props) {
  const data = props.data;
  const [info, setInfo] = useState([]);
  const [errorForm, setErrorForm] = useState(false);
  const [succes, setSucces] = useState(false);
  const [loading, setLoading] = useState(false);
  const captcha = React.useRef(null);
  const [captchaValid, setCaptchaValid] = useState(false);
  const onChangeCaptcha = () => {
    if(captcha.current.getValue()){
      setCaptchaValid(true);
    }
  };
  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.id;

    setInfo({
      ...info,
      [name]: value,
    });
  };

  const enviarForm = (event) => {
    event.preventDefault();

    if (!info.nom || !info.correuElectronic || !info.missatge || !captchaValid) {
      setErrorForm(true);
    } else {
      setErrorForm(false);
      setLoading(true);
      Axios.post("/send", {
        ...info,
      })
        .then(function (response) {
          setSucces(response.data.enviat);
          setLoading(false);
        })
        .catch(function (error) {
          setLoading(false);
        });
    }
  };
 

  return (
    <div className="container shadows p-4 mt-4">
      <h1 className="titol">
        {" "}
        <Traduccio string="contacte" />{" "}
        <Envelope fontSize={24} className="mb-1 ms-1" />
      </h1>
      {succes ? (
        <div style={{ textAlign: "center", padding: "50px" }}>
          <Traduccio string="contacte.enviat" />
        </div>
      ) : (
        <form className="row g-3 mt-2">
          <div className="col-md-6 col-lg-4">
            <label htmlFor="nom" className="form-label">
              <Traduccio string="contacte.nom" />{" "}
              <Asterisk fontSize={8} className="mb-2" />
            </label>
            <input
              type="text"
              className="form-control"
              id="nom"
              required
              onChange={handleInputChange}
            />
          </div>
          <div className="col-md-6">
            <label htmlFor="adressaPostal" className="form-label">
              <Traduccio string="contacte.adresa" />
            </label>
            <input
              type="text"
              className="form-control"
              id="adressaPostal"
              onChange={handleInputChange}
            />
          </div>
          <div className="col-md-6 col-lg-2">
            <label htmlFor="codiPostal" className="form-label">
              <Traduccio string="contacte.codiPostal" />
            </label>
            <input
              type="text"
              className="form-control"
              id="codiPostal"
              onChange={handleInputChange}
            />
          </div>

          <div className="col-md-6 col-lg-4">
            <label htmlFor="provincia" className="form-label">
              <Traduccio string="contacte.provincia" />
            </label>
            <input
              type="text"
              className="form-control"
              id="provincia"
              onChange={handleInputChange}
            />
          </div>
          <div className="col-md-6 col-lg-4">
            <label htmlFor="pais" className="form-label">
              <Traduccio string="contacte.pais" />
            </label>
            <input
              type="text"
              className="form-control"
              id="pais"
              onChange={handleInputChange}
            />
          </div>
          <div className="col-md-6 col-lg-4">
            <label htmlFor="telefon" className="form-label">
              <Traduccio string="contacte.telefon" />
            </label>
            <input
              type="text"
              className="form-control"
              id="telefon"
              onChange={handleInputChange}
            />
          </div>
          <div className="col-md-12 col-lg-6">
            <label htmlFor="assumpte" className="form-label">
              <Traduccio string="contacte.assumpte" />
            </label>
            <input
              type="text"
              className="form-control"
              id="assumpte"
              onChange={handleInputChange}
            />
          </div>
          <div className="col-md-12 col-lg-6">
            <label htmlFor="correuElectronic" className="form-label">
              <Traduccio string="contacte.correu" />{" "}
              <Asterisk fontSize={8} className="mb-2" />
            </label>
            <input
              type="text"
              className="form-control"
              id="correuElectronic"
              required
              onChange={handleInputChange}
            />
          </div>

          <div className="col-md-12">
            <label htmlFor="missatge" className="form-label">
              <Traduccio string="contacte.missatge" />{" "}
              <Asterisk fontSize={8} className="mb-2" />
            </label>
            <textarea
              type="text"
              className="form-control"
              id="missatge"
              required
              rows="4"
              onChange={handleInputChange}
            />
          </div>
          <div className="col-12">
            <div className="recaptcha">
              <label htmlFor="missatge" className="form-label">
                <Traduccio string="contacte.captcha" />{" "}
                <Asterisk fontSize={8} className="mb-2" />
              </label>
              <ReCAPTCHA
                style={{borderLeft: errorForm ? '2px solid #dc3545' : undefined}}
                ref={captcha}
                sitekey={process.env.REACT_APP_CAPTCHA}
                onChange={onChangeCaptcha}
                />
            </div>
            {errorForm && (
              <div className="errorForm">
                <Traduccio string="contacte.error" />
                <Asterisk fontSize={8} className="ms-1 mb-2" />
              </div>
            )}
            <button
              className="btn btn-primary mt-2"
              type="submit"
              onClick={(event) => enviarForm(event)}
            >
              <Traduccio string="contacte.enviar" />{" "}
              <Send fontSize={18} className="mb-1" />
            </button>
          </div>
        </form>
      )}
    </div>
  );
}

export default Contacte;
