import React from "react";
import { Globe } from "react-bootstrap-icons";
import "../css/DocumentsCallOut.css";

function LinksCallOut(props) {
  return (
    <div className="callout">
      {props.enllassos?.map((enllaç, index) => (
        <p className="linkfitxa mt-2" key={index}>
          <Globe
            style={{ fontSize: "22px", color: "#9e003c" }}
            className="me-2 mb-1"
          />

          <a href={enllaç.enlace} className="linkfitxa mt-2" target="_blank">
            {enllaç.titulo}
          </a>
        </p>
      ))}
    </div>
  );
}

export default LinksCallOut;
