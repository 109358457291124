import React from "react";
import "./css/Fitxa.css";
import DocumentCallOut from "../Components/SubComponents/DocumentsCallOut";
import LinksCallOut from "../Components/SubComponents/LinksCallOut";
import VideoYoutube from "../Components/SubComponents/VideoYoutube";
import Traduccio from "../Components/Traduccio";
import SpinnerLoading from "../Components/SubComponents/SpinnerLoading";
import { getDia } from "../Constants";

function Fitxa(props) {
  const data = props.data;

  return (
    <>
      <div className="container">
        {props.loading ? (
          <SpinnerLoading />
        ) : props.errorServei ? (
          <div className="container errorContainer text-center">
            {" "}
            <div className="mt-4">
              <Traduccio string="fitxa.errorServei" />
            </div>
          </div>
        ) : (
          <div className="container-fluid ">
            <h1 className="titol"> {data.titol} </h1>
            {props.noticia && (
              <h6 className="card-subtitle mt-2 mb-3 text-muted subtitolNoticia">
                {data.dataPublicacio && getDia(data.dataPublicacio)}
              </h6>
            )}
            {data.descripcioAbreujada && (
              <div className="mt-3 text justifyText">
                <div
                  dangerouslySetInnerHTML={{
                    __html: data.descripcioAbreujada,
                  }}
                ></div>
              </div>
            )}
            {data.imatge && (
              <img
                src={props.imatge}
                className="rounded mx-auto d-block img-fluid mt-3 mb-3"
                alt="Imatge"
                width="500px"
              />
            )}
            {data.descripcioExtensa && (
              <div className="mt-1 text mb-5 justifyText">
                {" "}
                <div
                  className="text"
                  dangerouslySetInnerHTML={{ __html: data.descripcioExtensa }}
                ></div>
              </div>
            )}
            {data.identificadorDocuments !== null && (
              <DocumentCallOut infoDocuments={data.identificadorDocuments} />
            )}
            {data.enllassos !== null && (
              <LinksCallOut enllassos={data.enllassos} />
            )}
            {data.videoYoutube === null || data.videoYoutube === "" ? (
              ""
            ) : (
              <VideoYoutube enllasVideo={data.videoYoutube} />
            )}
          </div>
        )}
      </div>
    </>
  );
}

export default Fitxa;
