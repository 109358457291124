import React, { useEffect, useState } from "react";
// import "../css/Tramits.css";
import { Link } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "../css/CarouselBanners.css";
import Traduccio from "../Traduccio";
import { Newspaper } from "react-bootstrap-icons";
import Axios from "../../Axios";
import { getDia } from "../../Constants";

function CarouselNoticias(props) {
  const [noticies, setNoticies] = useState([]);

  const cutTitle = (data) => {
    if (data) {
      if (data.length > 95) {
        return `${data.substring(0, 95)}...`;
      } else {
        return data;
      }
    } else {
      return "";
    }
  };

  useEffect(() => {
    const propietats = localStorage.getItem("propietats");

    Axios.get("/noticies", {
      params: {
        lang: localStorage.getItem("lang"),
        page: 1,
        pageSize: JSON.parse(propietats).noticiesLlistaTamanyPerDefecte,
      },
    })
      .then(function (response) {
        let noticiesArray = [];

        // setNoticies(response.data.noticiesFitxaList)
        const data = response.data.noticiesFitxaList;

        for (let i = 0; i < data.length; i = i + 2) {
          noticiesArray.push([data[i], data[i + 1]]);
        }

        setNoticies(noticiesArray);
      })
      .catch(function (error) {});
  }, []);

  return (
    <>
      <div className="card">
        <div className="card-body titolCard">
          <h5 className="card-title ">
            <Newspaper className="me-2 mb-1" fontSize={22} />
            <Traduccio string="actualitat" />
          </h5>
        </div>
        <Carousel
          additionalTransfrom={0}
          arrows={false}
          autoPlay={true}
          autoPlaySpeed={5000}
          centerMode={false}
          className=""
          containerClass="container-with-dots"
          dotListClass=""
          draggable
          focusOnSelect={false}
          itemClass=""
          keyBoardControl={true}
          renderDotsOutside={true}
          responsive={{
            desktop: {
              breakpoint: {
                max: 3000,
                min: 1024,
              },
              items: 1,
            },
            mobile: {
              breakpoint: {
                max: 464,
                min: 0,
              },
              items: 1,
            },
            tablet: {
              breakpoint: {
                max: 1024,
                min: 464,
              },
              items: 1,
            },
          }}
          showDots={true}
          infinite={true}
          sliderClass=""
          slidesToSlide={1}
          heigth={200}
          swipeable
        >
          {noticies.map((noticia, index) => {
            return (
              <div className="col p-2" key={index} style={{ height: "260px" }}>
                <Link
                  className="carouselNoticiesLink mt-1"
                  style={{ fontSize: "16px" }}
                  to={`/noticies/${noticia[0].codigo}`}
                >
                  {cutTitle(noticia[0]?.titol)}
                </Link>
                <h6 className="card-subtitle my-1  mb-3 text-muted subtitolNoticia">
                  {noticia[0].dataPublicacio &&
                    getDia(noticia[0].dataPublicacio)}
                </h6>
                {noticia[1] && (
                  <>
                    <Link
                      className="carouselNoticiesLink "
                      style={{ fontSize: "16px" }}
                      to={`/noticies/${noticia[1].codigo}`}
                    >
                      {cutTitle(noticia[1]?.titol)}
                    </Link>
                    <h6 className="card-subtitle mt-1 text-muted subtitolNoticia">
                      {noticia[1].dataPublicacio &&
                        getDia(noticia[1].dataPublicacio)}
                    </h6>
                  </>
                )}
              </div>
            );
          })}
        </Carousel>
      </div>
    </>
  );
}

export default CarouselNoticias;
