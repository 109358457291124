import React, { useEffect, useState } from "react";
import Axios from "../Axios";
import AxiosTransparencia from "../AxiosTransparencia";
import Header from "../Components/Header2";
import Menu from "../Components/Menu";
// import Tramits from "../Components/Tramits";
// import Fitxa from "../Components/Fitxa2";
import Footer from "../Components/Footer";
// import BreadCrumb from "../Components/BreadCrumb";
import { useLocation, useParams } from "react-router";
import CarouselNoticias from "../Components/SubComponents/CarouselNoticias";
import CarouselInformes from "../Components/SubComponents/CarouselInformesTransparencia";
import Fitxa from "./Fitxa";
import Contacte from "./Contacte";
import Traduccio from "../Components/Traduccio";
import { infoPresentacio } from "./info";
import Noticies from "./Noticies";
import Enllasos from "./Enllasos";
import ResultatsCercador from "./ResultatsCercador";
import BreadCrumb from "../Components/SubComponents/BreadCrumb";
import DistribuidorPagines from "./DistribuidorPaginesEspecials";
import AreesAuditoria from "./AreesAuditoria";
// import SpinnerLoading from "../Components/SubComponents/SpinnerLoading";

// import Procediments from "../Components/Procediments";
import { traduir } from "../Constants";
// import ResultatsCercador from "../Components/PaginesEspecials/ResultatsCercador";

function Index(props) {
  let params = useParams();
  let location = useLocation();
  let query = useQuery();

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const [data, setData] = useState([]);
  const [dataInformes, setDataInformes] = useState([]);
  const [dataPresentacio, setDataPresentacio] = useState([]);
  const [mostrarContacte, setMostrarContacte] = useState(false);
  const [mostrarNoticies, setMostrarNoticies] = useState(false);
  const [mostrarEnllasos, setMostrarEnllasos] = useState(false);
  const [mostrarCerca, setMostrarCerca] = useState(false);
  const [properties, setProperties] = useState(JSON.parse(localStorage.getItem("propietats")));

  const [identificadorsSeccions, setIdentificadorsSeccions] = useState([]);
  const [errorServei, setErrorServei] = useState(false);
  const [titolBreadCurmb, setTitolBreadCrumb] = useState();
  const [route, setRoute] = useState("");
  const [imatge, setImatge] = useState("");
  const [loading, setLoading] = useState(false);
  const [resultatsCerca, setResultatsCerca] = useState([]);

  useEffect(() => {
    // Axios.get(`/propietats`, {})
    //   .then(function (response) {
    //     localStorage.setItem("propietats", JSON.stringify(response.data));
    //   })
    //   .catch(function (error) {});

    const informesFiscalitzacioRequestParams = new URLSearchParams();
    informesFiscalitzacioRequestParams.append("page", "0");
    informesFiscalitzacioRequestParams.append("sort", "dataAprovacio,desc");
    informesFiscalitzacioRequestParams.append("sort", "ordre,asc");
    informesFiscalitzacioRequestParams.append("size", "10");
    informesFiscalitzacioRequestParams.append("lang", localStorage.getItem("lang"));


    AxiosTransparencia.get('/api/portalTransparencia/informesFiscalitzacio', {
      params: informesFiscalitzacioRequestParams,
    })
        .then(function(response) {
          const rows = response.data['_embedded'].informeFiscalitzacios ?? [];
          let informes = [];

          for (let i = 0; i < rows.length; i = i + 2) {
            informes.push([rows[i], rows[i + 1]]);
          }
          setDataInformes(informes);
        })
        .catch(function(error) {
        });

    Axios.get("/baners", {
      params: {
        lang: localStorage.getItem("lang"),
      },
    })
      .then(function (response) {
        setData(response.data.baners);
      })
      .catch(function (error) {});

    setDataPresentacio(infoPresentacio);
  }, []);

  useEffect(() => {
    const ruta = location.pathname.split("/");

    setRoute(ruta[1]);

    if (params.codi) {
      setLoading(true);
      Axios.get(`/fitxaCos/${params.codi || params.id}`, {
        params: {
          lang: localStorage.getItem("lang"),
        },
      })
        .then(function (response) {
          setDataPresentacio(response.data);
          setImatge("data:;base64," + response.data.imatge);
          setTitolBreadCrumb(response.data.titol);
          setErrorServei(false);
          setLoading(false);
          setIdentificadorsSeccions(response.data.identificadorSeccions);
        })
        .catch(function (error) {
          setErrorServei(true);
          setLoading(false);
        });
    } else if (params.id) {
      setLoading(true);
      Axios.get(`/fitxaNoticia/${params.id}`, {
        params: {
          lang: localStorage.getItem("lang"),
        },
      })
        .then(function (response) {
          setDataPresentacio(response.data);
          setImatge("data:;base64," + response.data.imatge);
          setTitolBreadCrumb(response.data.titol);
          setErrorServei(false);
          setLoading(false);
          setIdentificadorsSeccions(response.data.identificadorSeccions);
        })
        .catch(function (error) {
          setErrorServei(true);
          setLoading(false);
        });
    } else {
      if (ruta[1] === "") {
        setLoading(true);
        Axios.get(`/iniciPortal`, {
          params: {
            lang: localStorage.getItem("lang"),
          },
        })
          .then(function (response) {
            setDataPresentacio(response.data.inicis[0]);
            setImatge("data:;base64," + response.data.inicis[0].imatge);
            setTitolBreadCrumb(response.data.inicis[0].titol);
            setErrorServei(false);
            setLoading(false);
            setIdentificadorsSeccions(
              response.data.inicis[0].identificadorSeccions
            );
          })
          .catch(function (error) {
            setErrorServei(true);
            setLoading(false);
          });
      }
    }

    if (ruta[1] === "cercador") {
      recerca();
    }
  }, [location.pathname, params.codi, location.search]);

  const recerca = () => {
    setLoading(true);
    Axios.post(`/cercarPortal?lang=${localStorage.getItem("lang")}`, {
      paraulesClau: query.get("query"),
    })
      .then(function (response) {
        setResultatsCerca(response.data);
        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
      });
  };

  const cutTitle = (titolNoticia) => {
    if (titolNoticia) {
      if (titolNoticia.length > 40) {
        return `${titolNoticia.substring(0, 40)}...`;
      } else {
        return titolNoticia;
      }
    }
  };

  const agafarTitol = () => {
    const arees = JSON.parse(localStorage.getItem("arees"));
    if (params.id) {
      return {
        titol: <Traduccio string="noticies" />,
        subtitol: cutTitle(titolBreadCurmb),
        href: "/noticies"
      };
    }
    switch (route) {
      case "noticies":
        if (query.get("query")) {
          return {
            titol: <Traduccio string="noticies" />,
            subtitol: <Traduccio string="breadcrumb.resultadosBuscador" />,
          };
        } else {
          return {
            titol: <Traduccio string="noticies" />,
            subtitol: "",
          };
        }

      case "enllassos":
        return {
          titol: <Traduccio string="enlaces" />,
          subtitol: "",
        };
      case "cercador":
        return {
          titol: <Traduccio string="breadcrumb.resultadosBuscador" />,
          subtitol: "",
        };
      case "arees-auditoria":
        if (params.area) {
          return {
            titol: arees?.submenuList.map((area) => {
              if (params.area === area.codi) {
                return `${arees.titol} > ${area.titol}`;
              }
            }),
            subtitol: "",
          };
        }

      default:
        return { titol: titolBreadCurmb, subtitol: "" };
    }
  };

  const distribuidorRutes = (route) => {
    if (params.id) {
      return (
        <Fitxa
          data={dataPresentacio}
          imatge={imatge}
          loading={loading}
          errorServei={errorServei}
          noticia={true}
        />
      );
    }

    switch (route) {
      case "noticies":
        return <Noticies />;
      case "enllassos":
        return <Enllasos />;
      case "cercador":
        return (
          <ResultatsCercador
            loading={false}
            resultats={resultatsCerca}
            cercador={query.get("query")}
          />
        );
      case "arees-auditoria":
        return <AreesAuditoria />;

      default:
        return (
          <DistribuidorPagines
            codi={params.codi !== undefined ? params.codi : ""}
            data={dataPresentacio}
            imatge={imatge}
            errorServei={errorServei}
            loading={loading}
            seccions={identificadorsSeccions}
          />
        );
    }
  };

  return (
    <>
      <Header canviarLlenguatge={props.canviarLlenguatge} />
      <Menu cerca={recerca} onPropertiesLoad={setProperties} />
      <BreadCrumb titolFitxa={agafarTitol()} />
      {properties != null &&
          <>
            <div className="container-fluid mt-2">
              <div className="row">
                {" "}
                <div className="col-sm-6 col-md-4 col-xl-2 col-lg-3  ">
                  <CarouselNoticias banners={data}></CarouselNoticias>
                  <CarouselInformes informes={dataInformes}></CarouselInformes>
                </div>
                <div className="col-sm-12 col-md-8 col-xl-10 col-lg-9 mt-1">
                  {distribuidorRutes(route)}
                </div>
              </div>
            </div>

            {/* {loadingTramits ? (
        <SpinnerLoading />
      ) : errorServei ? (
        <div className="container errorContainer text-center">
          {" "}
          <p className="mt-4">
            <Traduccio string="fitxa.errorServei" />
          </p>
        </div>
      ) : (
        <>
          <BreadCrumb titolFitxa={agafarTitol()} />
          <div className="container-fluid">
            <div className="row">
              <Tramits loadingTramits={loadingTramits} data={dataTramit} />

              {mostrarProcediment ? (
                <>
                  <Procediments
                    dataProcediment={dataProcediment}
                    dataNormativa={dataNormativa}
                    dataDocuments={dataDocuments}
                    dataTramit={dataTramits}
                    errorProcediment={errorProcediment}
                    loading={loading}
                  />
                </>
              ) : (
                ""
              )}

              {!mostrarProcediment && !mostrarCerca && !mostrarAnunci ? (
                <DistribuidorPagines
                  codi={params.codi !== undefined ? params.codi : ""}
                  data={data}
                  imatge={imatge}
                  errorServei={errorServei}
                  mostrarMapaWeb={mostrarMapaWeb}
                  mostrarDataHora={mostrarDataHora}
                  loading={loading}
                  seccions={identificadorsSeccions}
                />
              ) : (
                ""
              )}

              {mostrarCerca ? (
                <ResultatsCercador
                  loading={false}
                  resultats={resultatsCerca}
                  cercador={query.get("query")}
                />
              ) : (
                ""
              )}

              {mostrarAnunci ? (
                <Fitxa
                  codi={props.codi !== undefined ? props.codi : ""}
                  data={data}
                  imatge={imatge}
                  errorServei={errorServei}
                  loading={loading}
                />
              ) : (
                ""
              )}
            </div>
          </div>    )}*/}

            <Footer/>
          </>
      }
    </>
  );
}

export default Index;
