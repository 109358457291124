import React, { useEffect, useState } from "react";
import Axios from "../Axios";
import "./css/Fitxa.css";
import "./css/Noticies.css";
import Traduccio from "../Components/Traduccio";
import SpinnerLoading from "../Components/SubComponents/SpinnerLoading";
import CardNoticia from "../Components/SubComponents/CardNoticia";
import Paginacio from "../Components/SubComponents/Paginacio";
import { traduir } from "../Constants";
import { ArrowLeft, Search } from "react-bootstrap-icons";
import { useLocation, useParams } from "react-router";
import { useHistory } from "react-router-dom";

function Noticies(props) {
  //   const data = props.data;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [cercar, setCercar] = useState("");
  const [mostrarResultats, setMostrarResultats] = useState(false);
  const [resultats, setResultats] = useState([]);
  const [totalPagines, setTotalPagines] = useState();
  const [paginaActual, setPaginaActual] = useState(1);
  const propietats = localStorage.getItem("propietats");
  const [resultatsPerPagina] = useState(
    JSON.parse(propietats).noticiesLlistaTamanyPerDefecte ?? 6
  );

  let query = useQuery();
  let history = useHistory();
  let location = useLocation();
  const ruta = location.pathname.split("/");

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  // Carrega Inicial de Noticies
  const carregarNoticies = () => {
    setLoading(true);

    setPaginaActual(1);
    Axios.get("/noticies", {
      params: {
        lang: localStorage.getItem("lang"),
        page: 1,
        pageSize: resultatsPerPagina,
      },
    })
      .then(function (response) {
        setData(response.data);
        setLoading(false);
        setMostrarResultats(false);
        setTotalPagines(
          Math.ceil(response.data.numeroElementos / resultatsPerPagina)
        );
      })
      .catch(function (error) {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (ruta[1] === "noticies") {
      if (query.get("query") === null) {
        setMostrarResultats(false);
        carregarNoticies();
      } else {
        recerca(query.get("query"));
        setMostrarResultats(true);
      }
    }
  }, [query.get("query")]);
  // Carrega principal de recerca.

  const recerca = (paraula) => {
    setLoading(true);
    setPaginaActual(1);
    Axios.post(
      `/cercarNoticies?lang=${localStorage.getItem(
        "lang"
      )}&page=${1}&pageSize=${resultatsPerPagina}`,
      {
        paraulesClau: paraula,
      }
    )
      .then(function (response) {
        setResultats(response.data);
        setLoading(false);
        setMostrarResultats(true);
        setTotalPagines(
          Math.ceil(response.data.numeroElementos / resultatsPerPagina)
        );
      })
      .catch(function (error) {
        setLoading(false);
      });
  };

  // Paginació per els botons envant i enrerre.
  const paginacioNext = async (pag) => {
    setLoading(true);

    Axios.get(
      `/noticies?lang=${localStorage.getItem("lang")}&page=${
        pag === "next" ? paginaActual + 1 : paginaActual - 1
      }&pageSize=${resultatsPerPagina}`,
      {}
    )
      .then(function (response) {
        setData(response.data);
        setLoading(false);
        setMostrarResultats(false);
        setTotalPagines(
          Math.ceil(response.data.numeroElementos / resultatsPerPagina)
        );
      })
      .catch(function (error) {
        setLoading(false);
      });

    setPaginaActual(pag === "next" ? paginaActual + 1 : paginaActual - 1);
  };

  // Paginació per anar a una pàgina en concret.

  const paginacio = async (num_pag) => {
    setLoading(true);

    Axios.get(
      `/noticies?lang=${localStorage.getItem(
        "lang"
      )}&page=${num_pag}&pageSize=${resultatsPerPagina}`,
      {}
    )
      .then(function (response) {
        setData(response.data);
        setLoading(false);
        setMostrarResultats(false);
        setTotalPagines(
          Math.ceil(response.data.numeroElementos / resultatsPerPagina)
        );
      })
      .catch(function (error) {
        setLoading(false);
      });

    setPaginaActual(num_pag);
  };

  //Paginació de la cerca (next/prev).
  const paginacioNextCerca = async (pag) => {
    setLoading(true);

    Axios.post(
      `/cercarNoticies?lang=${localStorage.getItem("lang")}&page=${
        pag === "next" ? paginaActual + 1 : paginaActual - 1
      }&pageSize=${resultatsPerPagina}`,
      {
        paraulesClau: cercar,
      }
    )
      .then(function (response) {
        setResultats(response.data);
        setLoading(false);
        setMostrarResultats(true);
        setTotalPagines(
          Math.ceil(response.data.numeroElementos / resultatsPerPagina)
        );
      })
      .catch(function (error) {
        setLoading(false);
      });

    setPaginaActual(pag === "next" ? paginaActual + 1 : paginaActual - 1);
  };

  // Paginació de la cerca a una pàgina en concret.
  const paginacioCerca = async (num_pag) => {
    setLoading(true);

    Axios.post(
      `/cercarNoticies?lang=${localStorage.getItem(
        "lang"
      )}&page=${num_pag}&pageSize=${resultatsPerPagina}`,
      {
        paraulesClau: cercar,
      }
    )
      .then(function (response) {
        setResultats(response.data);
        setLoading(false);
        setMostrarResultats(true);
        setTotalPagines(
          Math.ceil(response.data.numeroElementos / resultatsPerPagina)
        );
      })
      .catch(function (error) {
        setLoading(false);
      });

    setPaginaActual(num_pag);

    setLoading(false);
  };

  // recerca
  const cercarPerParaulaClau = (e) => {
    e.preventDefault();
    history.push(`/noticies?query=${cercar}`);
  };

  return (
    <div className="container">
      <div className="row">
        <h5 className="titol">
          {" "}
          <Traduccio string="noticies" />
        </h5>

        <div className="row">
          <div className="col-lg-3">
            <form
              className="d-flex col"
              onSubmit={(e) => cercarPerParaulaClau(e)}
            >
              <input
                className="form-control cercadorNoticies"
                type="search"
                placeholder={traduir("noticies.cercador")}
                aria-label="Search"
                id="cercador"
                defaultValue={""}
                onChange={(e) => e.preventDefault() + setCercar(e.target.value)}
              ></input>

              <button
                className="btn btnCercador btnCercadorNoticies"
                type="submit"
                onClick={(e) => cercarPerParaulaClau(e)}
              >
                <Search />
              </button>
            </form>
          </div>
        </div>

        {loading ? (
          <SpinnerLoading />
        ) : props.errorServei ? (
          <div className="container errorContainer text-center">
            {" "}
            <div className="mt-4">
              <Traduccio string="fitxa.errorServei" />
            </div>
          </div>
        ) : (
          <>
            <div className="row">
              <div className="clearfix">
                {mostrarResultats && resultats.numeroElementos > 0 && (
                  <h6 className="noResultatsCercaPrincipal float-start mt-3 pt-2">
                    <Traduccio string="noticies.mostrant" />{" "}
                    {resultats.numeroElementos}{" "}
                    <Traduccio string="noticies.resultatsDe" /> "
                    {query.get("query")}"
                  </h6>
                )}
                {mostrarResultats && (
                  <button
                    type="button"
                    className="btn btn-outline-primary float-end"
                    onClick={(e) => {
                      history.push("/noticies");
                    }}
                  >
                    <ArrowLeft className="me-2" fontSize={20} />
                    <Traduccio string="cataleg.tornar" />
                  </button>
                )}
              </div>
            </div>

            <div className="row">
              {!mostrarResultats ? (
                <>
                  {data?.noticiesFitxaList?.map((noticia, index) => {
                    return <CardNoticia data={noticia} key={index} />;
                  })}

                  {data.numeroElementos > resultatsPerPagina && (
                    <div className="row mt-5 m-0 justify-content-center">
                      <div className="col-auto">
                        <Paginacio
                          totalPagines={totalPagines}
                          paginaActual={paginaActual}
                          pagNext={paginacioNext}
                          paginacio={paginacio}
                        />
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <>
                  {resultats.numeroElementos === 0 ? (
                    <div className="noResultatsCercaPrincipal mt-4">
                      <Traduccio string="cataleg.noCoincidenicaPer" /> "
                      {query.get("query")}
                      ".
                    </div>
                  ) : (
                    resultats?.noticiesFitxaList.map((result, index) => {
                      return <CardNoticia data={result} key={index} />;
                    })
                  )}

                  {resultats.numeroElementos > resultatsPerPagina && (
                    <div className="row mt-5 m-0 justify-content-center">
                      <div className="col-auto">
                        <Paginacio
                          totalPagines={totalPagines}
                          paginaActual={paginaActual}
                          pagNext={paginacioNextCerca}
                          paginacio={paginacioCerca}
                        />
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Noticies;
