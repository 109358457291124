import React from "react";
import "../css/DocumentsCallOut.css";


function VideoYoutube(props) {


  return (
    <div className="mt-5">
    <iframe
      width="100%"
      height="500"
      src={props.enllasVideo}
      title="YouTube video player"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    ></iframe>
  </div>
  );
}

export default VideoYoutube;
