import React, { useEffect, useState } from "react";
import "./css/MapaWeb.css";
import Axios from "../Axios";
import Traduccio from "../Components/Traduccio";
import SpinnerLoading from "../Components/SubComponents/SpinnerLoading";

function Geolocalitzacio(props) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    Axios.get("/mapes", {
      params: {
        lang: localStorage.getItem("lang"),
      },
    })
      .then(function (response) {
        setData(response.data.mapesFitxaList);
        setLoading(false);
      })
      .catch(function (error) {});
  }, []);

  return (
    <>
      {loading ? (
        <SpinnerLoading />
      ) : (
        <div className="container">
          <h1 className="titol text-center">{props.data.titol}</h1>

          {data?.map((oficina, index) => {
            return (
              <div key={index}>
                <div className="mt-3 ps-3 textProcediment">
                  <p className="labelProcediment">
                    <strong>{oficina.titol}</strong>
                  </p>{" "}
                  <div
                    className="text"
                    dangerouslySetInnerHTML={{ __html: oficina.descripcio }}
                  ></div>
                  <a href={oficina.urlExterna}>
                    <Traduccio string="oficinas" />
                  </a>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
}

export default Geolocalitzacio;
