import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Index from "./Pages/index";
import "./Custom.scss";
import { Component } from "react";
import IdiomaContext from "./Context/IdiomaContext.jsx";
import "../node_modules/bootstrap/dist/js/bootstrap.js";

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      llenguatge: "",
    };

    //Agafam l'idioma per defecte del navegador, sempre el localStorage sigui buit.

    if (localStorage.getItem("lang") === null) {
      const lang = window.navigator.language;
      if (lang.includes("es")) {
        this.state.llenguatge = "es";
      } else if (lang.includes("ca")) {
        this.state.llenguatge = "ca";
      } else {
        this.state.llenguatge = "es";
      }

      localStorage.setItem("lang", this.state.llenguatge);
    } else {
      this.state.llenguatge = localStorage.getItem("lang");
    }
  }

  //Funció per canviar l'idioma.
  canviarLlenguatge = (id) => {
    localStorage.setItem("lang", id);
    this.setState({
      llenguatge: localStorage.getItem("lang"),
    });
  };

  render() {
    return (
      <div className="App">
        <IdiomaContext.Provider value={this.state.llenguatge}>
          <Router>
            <Switch>
              <Route
                exact
                path="/"
                render={() => (
                  <Index
                    key={"Index-" + this.state.llenguatge}
                    canviarLlenguatge={this.canviarLlenguatge}
                  />
                )}
              ></Route>
              <Route
                exact
                path="/fitxa/:codi"
                render={() => (
                  <Index
                    key={"Index-" + this.state.llenguatge}
                    canviarLlenguatge={this.canviarLlenguatge}
                  />
                )}
              ></Route>
              <Route
                exact
                path="/cercador"
                render={() => (
                  <Index
                    key={"Index-" + this.state.llenguatge}
                    canviarLlenguatge={this.canviarLlenguatge}
                  />
                )}
              ></Route>
              <Route
                exact
                path="/noticies"
                render={() => (
                  <Index
                    key={"Index-" + this.state.llenguatge}
                    canviarLlenguatge={this.canviarLlenguatge}
                  />
                )}
              ></Route>
              <Route
                exact
                path="/noticies/:id"
                render={() => (
                  <Index
                    key={"Index-" + this.state.llenguatge}
                    canviarLlenguatge={this.canviarLlenguatge}
                  />
                )}
              ></Route>

              <Route
                exact
                path="/enllassos"
                render={() => (
                  <Index
                    key={"Index-" + this.state.llenguatge}
                    canviarLlenguatge={this.canviarLlenguatge}
                  />
                )}
              ></Route>
              <Route
                exact
                path="/arees-auditoria/:area"
                render={() => (
                  <Index
                    key={"Index-" + this.state.llenguatge}
                    canviarLlenguatge={this.canviarLlenguatge}
                  />
                )}
              ></Route>
            </Switch>
          </Router>
        </IdiomaContext.Provider>
      </div>
    );
  }
}

export default App;
