import React, { useEffect, useState } from "react";
import MapaWeb from "./MapaWeb";
import Fitxa from "./Fitxa";
import Geolocatizacio from "./Geolocalitzacio";
import Contacte from "./Contacte";

function DistribuidorPagines(props) {
  const [properties, setProperties] = useState([]);

  useEffect(() => {
    const propietats = localStorage.getItem("propietats");
    setProperties(JSON.parse(propietats));
  }, []);

  let seccioPag = "fitxa";

  props.seccions.map((seccio) => {
    if (seccio.codiEstandar === properties.mapaWeb) {
      seccioPag = seccio.codiEstandar;
    } else if (seccio.codiEstandar === properties.procedimiento) {
      seccioPag = seccio.codiEstandar;
    } else if (seccio.codiEstandar === properties.geolocalitzacio) {
      seccioPag = seccio.codiEstandar;
    } else if (seccio.codiEstandar === "portal-contacte") {
      seccioPag = "portal-contacte";
    }
  });

  switch (seccioPag) {
    // case "fitxa":
    //   return (
    //     <Fitxa
    //       codi={props.codi !== undefined ? props.codi : ""}
    //       data={props.data}
    //       imatge={props.imatge}
    //       errorServei={props.errorServei}
    //       mostrarMapaWeb={props.mostrarMapaWeb}
    //       mostrarDataHora={props.mostrarDataHora}
    //       loading={props.loading}
    //     />
    //   );
    case properties.mapaWeb:
      return (
        <MapaWeb
          data={props.data}
          errorServei={props.errorServei}
          loading={props.loading}
        />
      );

    case properties.geolocalitzacio:
      return (
        <Geolocatizacio
          data={props.data}
          errorServei={props.errorServei}
          loading={props.loading}
        />
      );

    case "portal-contacte":
      return <Contacte />;
    default:
      return <Fitxa data={props.data} imatge={props.imatge}   loading={props.loading}/>;
  }
}

export default DistribuidorPagines;
