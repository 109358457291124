import React, { useEffect, useState } from "react";
import "./css/Footer.css";
import Axios from "../Axios";
import { Link } from "react-router-dom";
import CarouselBanners from "./SubComponents/CarouselBanners";

function Footer(props) {
  const [data, setData] = useState([]);
  const [dataPeu, setDataPeu] = useState([]);

  useEffect(() => {
    Axios.get("/baners", {
      params: {
        lang: localStorage.getItem("lang"),
      },
    })
      .then(function (response) {
        setData(response.data.baners);
      })
      .catch(function (error) {});

    Axios.get("/peu", {
      params: {
        lang: localStorage.getItem("lang"),
      },
    })
      .then(function (response) {
        setDataPeu(response.data.fitxesPeu);
      })
      .catch(function (error) {});
  }, []);

  return (
    <footer
      className="text-center text-white"
      style={{ backgroundColor: "#eeeeee" }}
    >
      <div className="container pe-3 ps-3 pt-3">
        <CarouselBanners baners={data} />
      </div>

      <div
        className="text-center textGris p-3"
        style={{ backgroundColor: "#caced1" }}
      >
        {dataPeu.map((peu, index) => {
          if (peu.imatge === null) {
            return (
              <Link
                className="textGris me-2 ms-2"
                to={`/fitxa/${peu.codigo}`}
                key={index}
              >
                {peu.titol}
              </Link>
            );
          } else {
            if (peu.url === null) {
              return (
                <Link
                  className="textGris me-2 ms-2"
                  to={`/fitxa/${peu.codigo}`}
                  key={index}
                >
                  <img src={`data:;base64,${peu.imatge}`} alt={peu.titol} />
                </Link>
              );
            } else {
              return (
                <a className="textGris me-2 ms-2" href={peu.url} key={index}>
                  <img src={`data:;base64,${peu.imatge}`} alt={peu.titol} />
                </a>
              );
            }
          }
        })}
      </div>
    </footer>
  );
}

export default Footer;
