import React, { Component } from "react";
import $ from "jquery";
import "../css/Paginacio.css";
class Paginacio extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  comprovarBotons() {
    if (this.props.paginaActual == 1) {
      $("#prev").addClass("disabled");
    } else {
      $("#prev").removeClass("disabled");
    }

    if (this.props.paginaActual === this.props.totalPagines) {
      $("#next").addClass("disabled");
    } else {
      $("#next").removeClass("disabled");
    }
  }

  componentDidMount() {
    this.comprovarBotons();
  }

  render() {
    // let pag = [];

    // pag.push(
    //   <li id="prev" className="page-item" key="prev">
    //     <button
    //       className="page-link"
    //       aria-label="Previous"
    //       onClick={() => this.props.pagNext("prev")}
    //     >
    //       <span aria-hidden="true">&laquo;</span>
    //     </button>
    //   </li>
    // );

    // for (let i = 1; i <= this.props.totalPagines; i++) {
    //   if (this.props.paginaActual == i) {
    //     pag.push(
    //       <li className="page-item active" key={i}>
    //         {" "}
    //         <button
    //           className="page-link"
    //           onClick={() => this.props.paginacio(i)}
    //         >
    //           {" "}
    //           {i}
    //         </button>{" "}
    //       </li>
    //     );
    //   } else {
    //     pag.push(
    //       <li className="page-item" key={i}>
    //         {" "}
    //         <button
    //           className="page-link"
    //           onClick={() => this.props.paginacio(i)}
    //         >
    //           {" "}
    //           {i}
    //         </button>{" "}
    //       </li>
    //     );
    //   }
    // }

    // pag.push(
    //   <li id="next" className="page-item" key="next">
    //     <button
    //       className="page-link "
    //       onClick={() => this.props.pagNext("next")}
    //       aria-label="Next"
    //     >
    //       <span aria-hidden="true">&raquo;</span>
    //     </button>
    //   </li>
    // );

    let pag = [];

    if (this.props.totalPagines) {
      if (this.props.totalPagines < 10) {
        pag.push(
          <li id="prev" className="page-item disabled" key="prev">
            <button
              className="page-link"
              aria-label="Previous"
              onClick={() => this.props.pagNext("prev")}
            >
              <span aria-hidden="true">&laquo;</span>
              <span className="sr-only"></span>
            </button>
          </li>
        );

        for (let i = 1; i <= this.props.totalPagines; i++) {
          pag.push(
            <li
              className={
                this.props.paginaActual === i ? "page-item active" : "page-item"
              }
              key={i}
            >
              {" "}
              <button
                className="page-link"
                onClick={() => this.props.paginacio(i)}
              >
                {" "}
                {i}
              </button>{" "}
            </li>
          );
        }

        pag.push(
          <li id="next" className="page-item" key="next">
            <button
              className="page-link "
              onClick={() => this.props.pagNext("next")}
              aria-label="Next"
            >
              <span aria-hidden="true">&raquo;</span>
              <span className="sr-only"></span>
            </button>
          </li>
        );
      } else {
        if (
          this.props.paginaActual !== 1 &&
          this.props.paginaActual !== 2 &&
          this.props.totalPagines !== this.props.paginaActual &&
          this.props.totalPagines - 1 !== this.props.paginaActual
        ) {
          pag.push(
            <li className="page-item" key={1}>
              {" "}
              <button
                className="page-link"
                onClick={() => this.props.paginacio(1)}
              >
                {" "}
                {1}
              </button>{" "}
            </li>
          );

          pag.push(
            <li id="prev" className="page-item" key="prev">
              <button
                className="page-link"
                aria-label="Previous"
                onClick={() => this.props.pagNext("prev")}
              >
                <span aria-hidden="true">&laquo;</span>
                <span className="sr-only"></span>
              </button>
            </li>
          );

          pag.push(
            <li className="page-item" key={this.props.paginaActual - 1}>
              {" "}
              <button
                className="page-link"
                onClick={() =>
                  this.props.paginacio(this.props.paginaActual - 1)
                }
              >
                {" "}
                {this.props.paginaActual - 1}
              </button>{" "}
            </li>
          );

          pag.push(
            <li className="page-item active" key={this.props.paginaActual}>
              {" "}
              <button
                className="page-link"
                onClick={() => this.props.paginacio(this.props.paginaActual)}
              >
                {" "}
                {this.props.paginaActual}
              </button>{" "}
            </li>
          );

          pag.push(
            <li className="page-item" key={this.props.paginaActual + 1}>
              {" "}
              <button
                className="page-link"
                onClick={() =>
                  this.props.paginacio(this.props.paginaActual + 1)
                }
              >
                {" "}
                {this.props.paginaActual + 1}
              </button>{" "}
            </li>
          );

          pag.push(
            <li id="next" className="page-item" key={"next"}>
              <button
                className="page-link "
                onClick={() => this.props.pagNext("next")}
                aria-label="Next"
              >
                <span aria-hidden="true">&raquo;</span>
                <span className="sr-only"></span>
              </button>
            </li>
          );

          pag.push(
            <li className="page-item" key={this.props.totalPagines}>
              {" "}
              <button
                className="page-link"
                onClick={() => this.props.paginacio(this.props.totalPagines)}
              >
                {" "}
                {this.props.totalPagines}
              </button>{" "}
            </li>
          );
        } else {
          pag.push(
            <li
              className={
                this.props.paginaActual === 1 ? "page-item active" : "page-item"
              }
              key={1}
            >
              <button
                className="page-link"
                onClick={() => this.props.paginacio(1)}
              >
                {1}
              </button>
            </li>
          );
          pag.push(
            <li
              className={
                this.props.paginaActual === 2 ? "page-item active" : "page-item"
              }
              key={2}
            >
              <button
                className="page-link"
                onClick={() => this.props.paginacio(2)}
              >
                {2}
              </button>
            </li>
          );
          pag.push(
            <li
              className={
                this.props.paginaActual === 3 ? "page-item active" : "page-item"
              }
              key={3}
            >
              <button
                className="page-link"
                onClick={() => this.props.paginacio(3)}
              >
                {3}
              </button>
            </li>
          );
          pag.push(
            <li className="page-item" key={"x"}>
              <button className="page-link">...</button>
            </li>
          );
          pag.push(
            <li
              className={
                this.props.paginaActual === this.props.totalPagines - 2
                  ? "page-item active"
                  : "page-item"
              }
              key={this.props.totalPagines - 2}
            >
              <button
                className="page-link"
                onClick={() =>
                  this.props.paginacio(this.props.totalPagines - 2)
                }
              >
                {this.props.totalPagines - 2}
              </button>
            </li>
          );
          pag.push(
            <li
              className={
                this.props.paginaActual === this.props.totalPagines - 1
                  ? "page-item active"
                  : "page-item"
              }
              key={this.props.totalPagines - 1}
            >
              <button
                className="page-link"
                onClick={() =>
                  this.props.paginacio(this.props.totalPagines - 1)
                }
              >
                {this.props.totalPagines - 1}
              </button>
            </li>
          );
          pag.push(
            <li
              className={
                this.props.paginaActual === this.props.totalPagines
                  ? "page-item active"
                  : "page-item"
              }
              key={this.props.totalPagines}
            >
              <button
                className="page-link"
                onClick={() => this.props.paginacio(this.props.totalPagines)}
              >
                {this.props.totalPagines}
              </button>
            </li>
          );
        }
      }
    }


    this.comprovarBotons();

    return (
      <div>
        <nav aria-label="Page navigation example mr-auto">
          <ul className="pagination pagination-sm">{pag}</ul>
        </nav>
      </div>
    );
  }
}

export default Paginacio;
