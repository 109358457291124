import React from "react";
import "./css/Fitxa.css";
import SpinnerLoading from "../Components/SubComponents/SpinnerLoading";
import Traduccio from "../Components/Traduccio";
import CardResultatsContingut from "../Components/SubComponents/CardResultatsContingut";

function ResultatsCercador(props) {
  return (
    <div className="col-sm-8 col-md-8 col-lg-9 col-xl-10">
      {props.loading ? (
        <SpinnerLoading />
      ) : (
        <div className="container-fluid containerFitxa">
          <h1 className="titol text-center">
            <Traduccio string="cataleg.resultatsDe" /> "{props.cercador}"
          </h1>

          {props.resultats?.numeroElementos === 0 && (
            <div className="noResultats">
              <Traduccio string="cataleg.noCoincidenica" />
            </div>
          )}
          {/* <h2 className="subtitolRecerca mt-4">
            <Traduccio string="index.procediments" />
          </h2>
          {props.resultats?.procediments?.length === 0 && (
            <div className="noResultatsCercaPrincipal">
              <Traduccio string="cataleg.noCoincidenica" />
            </div>
          )}
          {props.resultats?.procediments?.map((result, index) => {
            return <CardResultats key={index} data={result} />;
          })} */}

          {props.resultats?.contingut?.length === 0 && (
            <div className="noResultatsCercaPrincipal">
              <Traduccio string="cataleg.noCoincidenica" />
            </div>
          )}
          {props.resultats?.contingut?.map((result, index) => {
            return <CardResultatsContingut key={index} data={result} />;
          })}
        </div>
      )}
    </div>
  );
}

export default ResultatsCercador;
