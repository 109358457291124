import React, { useEffect, useState } from "react";
import { List, Search } from "react-bootstrap-icons";
import "./css/Menu.css";
import Axios from "../Axios";
import $ from "jquery";
import { traduir } from "../Constants";
import { Link, useHistory } from "react-router-dom";

function Menu(props) {
  const [data, setData] = useState([]);
  const [cercar, setCercar] = useState("");
  const [properties, setProperties] = useState([]);

  let history = useHistory();

  useEffect(() => {
    const propietats = JSON.parse(localStorage.getItem("propietats"));
    setProperties(propietats);

    Axios.get(`/propietats`, {})
      .then(function (response) {
        setProperties(response.data);
        localStorage.setItem("propietats", JSON.stringify(response.data));
        props.onPropertiesLoad?.(response.data);
      })
      .catch(function (error) {});
    // Axios.get(`/propietats`, {})
    //   .then(function (response) {
    //     setProperties(response.data);
    //     localStorage.setItem("propietats", JSON.stringify(response.data));
    //   })
    //   .catch(function (error) {});

    Axios.get("/menusPortal", {
      params: {
        lang: localStorage.getItem("lang"),
      },
    })
      .then(function (response) {
        setData(response.data);
        response.data.map((menuTitol) => {
          if (menuTitol.codi === propietats?.menuAreesAuditoriaPortalWeb) {
            localStorage.setItem("arees", JSON.stringify(menuTitol));
          }
        });
      })
      .catch(function (error) {});
  }, []);

  const mostrarDropdown = (num) => {
    $(".dropList").removeClass("show");
    $(`.dropList${num}`).addClass("show");
  };

  const ocultarDropdown = (num) => {
    $(`.dropList${num}`).removeClass("show");
  };

  const recerca = (e) => {
    e.preventDefault();
    history.push(`/cercador?query=${cercar}`);
  };

  return (
    <>
      <nav className="navbar navbar-expand-lg nav2">
        <div className="container-fluid">
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarTogglerDemo01"
            aria-controls="navbarTogglerDemo01"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <List color="white" />
          </button>
          <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              {data?.map((menuTitol, categoryIndex) => {
                const linksMenu = menuTitol;

                switch (menuTitol.codi) {
                  case properties.menuAreesAuditoriaPortalWeb:
                    return (
                      <li
                        className={`nav-item `}
                        onMouseLeave={() => ocultarDropdown(categoryIndex)}
                        key={`1${categoryIndex}`}
                      >
                        <div
                          className="dropdown"
                          id="drop1"
                          onMouseEnter={() => mostrarDropdown(categoryIndex)}
                        >
                          <a
                            className="nav-link dropdown-toggle"
                            role="button"
                            id={`dropdownMenuLink${categoryIndex}`}
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            {menuTitol.titol}
                          </a>
                          <ul
                            className={`dropdown-menu dropList dropList${categoryIndex}`}
                            id="dropdown-menu"
                            aria-labelledby="dropdownMenuButton"
                          >
                            <div className="row">
                              {linksMenu.submenuList.map(
                                (links, linksIndex) => (
                                  <div
                                    className="col-xl-4 col-md-6"
                                    key={linksIndex}
                                  >
                                    <li>
                                      <Link
                                        className="dropdown-item"
                                        to={`/arees-auditoria/${links.codi}`}
                                      >
                                        {links.titol}
                                      </Link>
                                    </li>
                                  </div>
                                )
                              )}
                            </div>
                          </ul>
                        </div>
                      </li>
                    );
                  case properties.menuQuiSomPortalWeb:
                    return (
                      <li
                        className={`nav-item `}
                        onMouseLeave={() => ocultarDropdown(categoryIndex)}
                        key={`1${categoryIndex}`}
                      >
                        <div
                          className="dropdown"
                          id="drop1"
                          onMouseEnter={() => mostrarDropdown(categoryIndex)}
                        >
                          <a
                            className="nav-link dropdown-toggle"
                            role="button"
                            id={`dropdownMenuLink${categoryIndex}`}
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            {menuTitol.titol}
                          </a>
                          <ul
                            className={`dropdown-menu dropList dropList${categoryIndex}`}
                            id="dropdown-menu"
                            aria-labelledby="dropdownMenuButton"
                          >
                            <div className="row">
                              {linksMenu.identificadorFitxes.map(
                                (links, linksIndex) => (
                                  <div
                                    className="col-xl-4 col-md-6"
                                    key={linksIndex}
                                  >
                                    <li>
                                      <Link
                                        className="dropdown-item"
                                        to={`/fitxa/${links.codigo}`}
                                      >
                                        {links.titol}
                                      </Link>
                                    </li>
                                  </div>
                                )
                              )}
                            </div>
                          </ul>
                        </div>
                      </li>
                    );

                  case properties.menuNoticiesPortalWeb:
                    return (
                      <li className={`nav-item `} key={categoryIndex}>
                        <Link
                          className="nav-link "
                          role="button"
                          to={`/noticies`}
                        >
                          {menuTitol.titol}
                        </Link>
                      </li>
                    );

                  case properties.menuEnllassosInteresPortalWeb:
                    return (
                      <li className={`nav-item `} key={categoryIndex}>
                        <Link
                          className="nav-link "
                          role="button"
                          to={`/enllassos`}
                        >
                          {menuTitol.titol}
                        </Link>
                      </li>
                    );

                  default:
                    return (
                      <li className={`nav-item `} key={categoryIndex}>
                        <Link
                          className="nav-link "
                          role="button"
                          to={`/fitxa/${menuTitol.identificadorFitxes[0]?.codigo}`}
                        >
                          {menuTitol.titol}
                        </Link>
                      </li>
                    );
                }
              })}
            </ul>
            <form className="d-flex" onSubmit={(e) => recerca(e)}>
              <input
                className="form-control cercar"
                type="search"
                placeholder={traduir("menu.cercador")}
                aria-label="Search"
                onChange={(e) => setCercar(e.target.value)}
              />
              <button
                className="btn btn-outline-primary btnCercador"
                type="submit"
              >
                <Search />
              </button>
            </form>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Menu;
