import React, { useEffect, useState } from 'react';
import './css/Enllasos.css';
import './css/Arees.css';
import Traduccio from '../Components/Traduccio';
import SpinnerLoading from '../Components/SubComponents/SpinnerLoading';
import { useParams } from 'react-router-dom';
import Axios from '../Axios';
import logoPdf from '../Img/icon_pdf.png';
import logoWord from '../Img/icon.doc.png';
import logoExcel from '../Img/icon_excel.png';
import { BoxArrowUpRight } from 'react-bootstrap-icons';

const tipusArxiu = (mime) => {
    if (mime?.includes('pdf')) {
        return logoPdf;
    } else if (mime?.includes('excel') || mime?.includes('spreadsheetml')) {
        return logoExcel;
    } else {
        return logoWord;
    }
};

function descarregarDocuments(codi, nom) {
    Axios.get(`/arxiusStreaming`, {
        params: {
            id: codi,
        },
        responseType: 'arraybuffer',
    })
        .then(function(response) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', nom);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        })
        .catch(function(error) {
        });
}

function FitxaArea(props) {
    const { area, depth = 0 } = props;
    return (
        <div>
            {depth > 0 ?
                <h6 className="titolSubSeccio">{area.titol}</h6>
                :
                <div className="d-flex titolsArees justify-content-between">
                    <h6 className="titolEnllas">{area.titol}</h6>
                </div>
            }
            <ul className="mt-2 list llistaArea">
                {area?.fitxaAreaList?.map((fitxa) => {
                    let dades = [];

                    dades.push(
                        <div className="descripcioSeccio" key={`descripcio${depth}-${fitxa.codigo}`}>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: fitxa?.descripcioAbreujada,
                                }}
                            ></div>
                        </div>
                    );

                    fitxa?.identificadorDocuments?.map((document) => {
                        let logo = tipusArxiu(document.mime);
                        dades.push(
                            <li className="linksEnllasos ms-2" key={`document${depth}-${document.codi}`}>
                                <button
                                    className="linkAreaDocument"
                                    onClick={() =>
                                        descarregarDocuments(document.codiArxiu, document.nomArxiu)
                                    }
                                >
                                    {document.titol}{' '}
                                    <img
                                        src={logo}
                                        className={`mb-2 ${depth > 0 ? "me-2" : ""}`}
                                        width="16px"
                                        alt="tipusArxiu"
                                    />
                                </button>
                            </li>
                        );
                    });
                    fitxa?.enllassos?.map((enllas) => {
                        dades.push(
                            <li className="linksEnllasos ms-2" key={`links${depth}-${enllas.codigo}`}>
                                {/* {enllas.titulo} */}
                                <a href={enllas.enlace} className="linkfitxa mt-2" target="_blank">
                                    {enllas.titulo}
                                    {depth === 0 &&
                                        <BoxArrowUpRight className="ms-2 mb-1" />
                                    }
                                </a>
                            </li>
                        );
                    });
                    return dades;
                })}
            </ul>
            <ul className="mt-4 list llistaSubseccions">
                {area?.subAreaList?.map((subArea) => (
                    <li>
                        <FitxaArea area={subArea} key={`subArea${depth}-${subArea.codi}`} depth={depth+1} />
                    </li>
                ))}
            </ul>
        </div>
    );
};


function AreesAuditoria(props) {
    let params = useParams();
    const [dataArea, setDataArea] = useState([]);
    const [loading, setLoading] = useState(false);
    const [errorServei, setErrorServei] = useState(false);

    useEffect(() => {
        if (params.area) {
            setLoading(true);

            Axios.get(`/area/${params.area}`, {
                params: {
                    lang: localStorage.getItem("lang"),
                },
            })
                .then(function (response) {
                    setDataArea(response.data);
                    setLoading(false);

                    setLoading(false);
                })
                .catch(function (error) {
                    setErrorServei(true);
                    setLoading(false);
                });
        }
    }, [params.area]);

    return (
        <>
            <div className="container">
                {loading ? (
                    <SpinnerLoading />
                ) : errorServei ? (
                    <div className="container errorContainer text-center">
                        {" "}
                        <div className="mt-4">
                            <Traduccio string="fitxa.errorServei" />
                        </div>
                    </div>
                ) : (
                    <div className="container-fluid ">
                        <div className="row">
                            <div className="col-xl-10">
                                <h5 className="titol mb-3">{dataArea.titol}</h5>
                                {dataArea?.subAreaList?.map((subArea, index) => {
                                    return <FitxaArea area={subArea} key={index} />
                                })}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}

export default AreesAuditoria;
