import React, { useEffect, useState } from "react";
import logo from "../Img/MIDA1gl_preview_rev_1.png";
import IMG_PANO from "../Img/panoramicaPortalWeb3.jpg";
import { ChevronDoubleRight, Eye, HandIndex } from "react-bootstrap-icons";
import Traduccio from "./Traduccio";
import "./css/Header.css";
import { agafarIdioma, primary } from "../Constants";
import Axios from "../Axios";
import { Link } from "react-router-dom";

function Header(props) {
  const [data, setData] = useState([]);
  const [links, setLinks] = useState([]);
  const [imgCapssalera, setImgCapssalera] = useState();
  const [hora, setHora] = useState([]);
  const [segons, setSegons] = useState(0);
  const [minuts, setMinuts] = useState(0);
  const [hores, setHores] = useState(0);

  let idioma = agafarIdioma();

  const peticioCapsalera = () => {
    Axios.get("/capssaleraPortal", {
      params: {
        lang: localStorage.getItem("lang"),
      },
    })
      .then(function (response) {
        setData(response.data);
        setLinks(response.data.fitxesCapssalera);
        response.data.fitxesCapssalera.map((fitxa) => {
          if (fitxa.imatge !== null) {
            setImgCapssalera(fitxa.imatge);
          }
        });
      })
      .catch(function (error) {});
  };

  useEffect(() => {
    peticioCapsalera();
  }, []);

  const backgroundImageURL = IMG_PANO;

  return (
    <div
      className="d-flex header"
      style={{
        backgroundImage: imgCapssalera
          ? `url(data:;base64,${imgCapssalera})`
          : `url(${backgroundImageURL})`,
      }}
    >
      <div className="p-2">
        {" "}
        <Link className="navbar-brand" to="/">
          {" "}
          <img
            src={logo}
            className="img-fluid"
            alt="logoSCI"
            width="280"
          />{" "}
        </Link>
      </div>
      <div className="ms-auto p-2 ">
        {" "}
        <nav className="nav navLinks" style={{ textAlign: "end" }}>
          <div className="btn-group groupButtons" role="group">
            <button
              className={`nav-link links ${idioma === "ca" ? "active" : ""}`}
              onClick={() => props.canviarLlenguatge("ca")}
            >
              CA |
            </button>

            <button
              className={`nav-link links ms-1 ${
                idioma === "es" ? "active" : ""
              }`}
              onClick={() => props.canviarLlenguatge("es")}
            >
              ES
            </button>
          </div>
          <a className="linkHeader" href={links[0]?.url}>
            <span className="navPortals">
              <HandIndex color="white" className="iconoLink2 me-2" />
              {links[0] === undefined ? "Seu Electrónica" : links[0].titol}
            </span>
          </a>
          <a className="linkHeader" href={links[1]?.url}>
            <span className="navPortals">
              <Eye color="white" className="iconoLink2 me-2" />
              {links[1] === undefined ? "Portal transparencia" : links[1].titol}
            </span>
          </a>
        </nav>
      </div>
    </div>
  );
}

export default Header;
