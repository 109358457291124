import React from "react";
import "../css/CardResultats.css";
import { Link } from "react-router-dom";
import { PlusCircleFill } from "react-bootstrap-icons";

function CardResultatsContingut(props) {
  return (
    <div className="card p-2 mb-1 cardResultat">
      <div className="row g-0">
        <div className="col-md-10">
          <div className="card-text procedimentLink">
            <Link
              to={`/fitxa/${props.data.codigo}`}
              className="procedimentLink"
            >
              <PlusCircleFill style={{ fontSize: "19px" }} className="me-2" />
              {props.data.titol}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardResultatsContingut;
