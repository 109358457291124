import React from "react";
import { ChevronDoubleRight } from "react-bootstrap-icons";
import "../css/CardNoticies.css";
import Traduccio from "../Traduccio";
import { Link } from "react-router-dom";
import { getDia } from "../../Constants";

function CardNoticia(props) {
  const data = props.data;
  const imatge = "data:;base64," + data.imatge;

  const cutDesc = () => {
    if (data.descripcioAbreujada) {
      if (data.descripcioAbreujada.length > 250) {
        return `${data.descripcioAbreujada.substring(0, 250)}...`;
      } else {
        return data.descripcioAbreujada;
      }
    } else {
      if (data.descripcioExtensa.length > 250) {
        return `${data.descripcioExtensa.substring(0, 250)}...`;
      } else {
        return data.descripcioExtensa;
      }
    }
  };

  return (
    <div className="col-lg-6 col-xl-4 mt-4">
      <div className="card cardNoticia h-100">
        {data.imatge && (
          <img src={imatge} className="card-img-top" height={200} alt="..." />
        )}
        <div className="card-body">
          <h5 className="card-title titolNoticia">{data.titol}</h5>
          {!data.imatge && (
            <div className="card-text mt-3 textNoticia">
              <div
                dangerouslySetInnerHTML={{
                  __html: cutDesc(),
                }}
              ></div>
            </div>
          )}
          <h6 className="card-subtitle mt-2 text-muted subtitolNoticia">
            {getDia(data.dataPublicacio)}
          </h6>
          <Link className="stretched-link" to={`/noticies/${data.codigo}`}>
            {" "}
          </Link>
        </div>

        <div className="card-footer">
          <a href={`/noticies/${data.codigo}`} className="card-link">
            <ChevronDoubleRight className="iconoLink2 me-1" />
            <Traduccio string="veureMes" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default CardNoticia;
