import axios from "axios";

const Axios = axios.create();

const username = "admin";
const password = "admin";
const basicAuth = "Basic " + btoa(username + ":" + password);

// Axios.defaults.baseURL = "http://10.35.3.44:8085/api/v1/rolsac";
Axios.defaults.baseURL = `${process.env.REACT_APP_SERVER_API}api/v1/rolsac`;

Axios.interceptors.request.use(
  function (conf) {
    conf.headers = {
      Authorization: basicAuth,
      "Content-Type": "application/json",
    };

    return conf;
  },
  function (error) {
    return Promise.reject(error);
  }
);

Axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default Axios;
