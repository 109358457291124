import React from "react";

function SpinnerLoading() {
  return (
    <div className="text-center marginSpinner">
      {" "}
      <div className="spinner-border text-secondary" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
      {/* <div
        className="spinner-grow spinner-grow-sm text-primary me-2"
        role="status"
      >
        <span className="visually-hidden">Loading...</span>
      </div>
      <div
        className="spinner-grow spinner-grow-sm text-primary  me-2"
        role="status"
      >
        <span className="visually-hidden">Loading...</span>
      </div>
      <div
        className="spinner-grow spinner-grow-sm text-primary  me-2"
        role="status"
      >
        <span className="visually-hidden">Loading...</span>
      </div>{" "} */}
    </div>
  );
}

export default SpinnerLoading;
