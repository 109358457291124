import React, { useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "../css/CarouselInformes.css";
import Traduccio from "../Traduccio";
import { Paperclip } from "react-bootstrap-icons";
import { getDia } from "../../Constants";

const portalTransparenciaURL = process.env.REACT_APP_TRANSPARENCIA_PORTAL_URL;

function CarouselInformes({ informes, ...props }) {
  const cutTitle = (data) => {
    if (data) {
      if (data.length > 95) {
        return `${data.substring(0, 95)}...`;
      } else {
        return data;
      }
    } else {
      return "";
    }
  };

  return (
    <>
      <div className="card cardInformes mt-4">
        <div className="card-body titolCard">
          <h5 className="card-title ">
            <Paperclip className="me-2 mb-1" fontSize={22} />
            <Traduccio string="informes" />
          </h5>
        </div>
        <Carousel
          additionalTransfrom={0}
          arrows={false}
          autoPlay={true}
          autoPlaySpeed={5000}
          centerMode={false}
          className=""
          containerClass="container-with-dots"
          dotListClass=""
          draggable
          focusOnSelect={false}
          itemClass=""
          keyBoardControl={true}
          renderDotsOutside={true}
          responsive={{
            desktop: {
              breakpoint: {
                max: 3000,
                min: 1024,
              },
              items: 1,
            },
            mobile: {
              breakpoint: {
                max: 464,
                min: 0,
              },
              items: 1,
            },
            tablet: {
              breakpoint: {
                max: 1024,
                min: 464,
              },
              items: 1,
            },
          }}
          showDots={true}
          infinite={true}
          sliderClass=""
          slidesToSlide={1}
          swipeable
        >
          {informes.map((informe, index) => {
            return (
              <div className="col p-2" key={index}>
                <div style={{ minHeight: "130px" }}>
                  <a
                    className="carouselNoticiesLink mt-1"
                    style={{ fontSize: "16px" }}
                    href={`${portalTransparenciaURL}informes?search=${informe[0]?.codi}`}
                  >
                    {cutTitle(informe[0]?.nom)}
                  </a>

                  <h6 className="card-subtitle my-1  mb-3 text-muted subtitolNoticia">
                    {informe[0]?.dataAprovacio &&
                      getDia(informe[0].dataAprovacio)}
                  </h6>
                </div>
                {informe[1] && (
                  <div style={{ minHeight: "130px" }}>
                    <a
                      className="carouselNoticiesLink "
                      style={{ fontSize: "16px" }}
                      href={`${portalTransparenciaURL}informes?search=${informe[1]?.codi}`}
                    >
                      {cutTitle(informe[1]?.nom)}
                    </a>
                    <h6 className="card-subtitle mt-1 text-muted subtitolNoticia">
                      {informe[1]?.dataAprovacio &&
                        getDia(informe[1].dataAprovacio)}
                    </h6>
                  </div>
                )}
              </div>
            );
          })}
        </Carousel>
      </div>
    </>
  );
}

export default CarouselInformes;
