import React, { useEffect, useState } from "react";
import Axios from "../Axios";
import "./css/Enllasos.css";
import Traduccio from "../Components/Traduccio";
import SpinnerLoading from "../Components/SubComponents/SpinnerLoading";
import {
  Diagram3Fill,
  FlagFill,
  CompassFill,
  Globe,
} from "react-bootstrap-icons";

function Enllasos(props) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorServei, setErrorServei] = useState(false);

  useEffect(() => {
    setLoading(true);

    Axios.get("/enllassos", {
      params: {
        lang: localStorage.getItem("lang"),
      },
    })
      .then(function (response) {
        setData(response.data);
        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
        setErrorServei(true);
      });
  }, []);

  const icons = [
    <FlagFill className="icon" fontSize={25} />,
    <Globe className="icon" fontSize={25} />,
    <CompassFill className="icon" fontSize={25} />,
    <Diagram3Fill className="icon" fontSize={25} />,
  ];

  return (
    <>
      <div className="container">
        {loading ? (
          <SpinnerLoading />
        ) : errorServei ? (
          <div className="container errorContainer text-center">
            {" "}
            <div className="mt-4">
              <Traduccio string="fitxa.errorServei" />
            </div>
          </div>
        ) : (
          <div className="container-fluid ">
            <div className="row">
              <div className="col-xl-10">
                <h5 className="titol mb-3">{data.titol}</h5>
                {data?.enllassos?.map((enllas, index) => {
                  const links = enllas;
                  return (
                    <div key={index}>
                      <div className="d-flex titolsEnllasos justify-content-between">
                        <h6 className="titolEnllas">{enllas.titol}</h6>
                        {enllas.imatge ? (
                          <img
                            src={`data:;base64,${enllas.imatge}`}
                            // className="rounded mx-auto d-block img-fluid mt-3 mb-3"
                            alt="icono"
                            // width="25px"
                            height="30px"
                          />
                        ) : (
                          icons[index]
                        )}
                      </div>
                      <ul className="mt-4 list llistaMapa">
                        {links.enllassos.map((link, linksIndex) => (
                          <li className="linksEnllasos" key={linksIndex}>
                            <a
                              className="linksEnllasos"
                              href={link.enlace}
                              target="_blank"
                            >
                              {link.titulo}{" "}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Enllasos;
