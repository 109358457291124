import React from "react";
import "../css/DocumentsCallOut.css";
import Axios from "../../Axios";
import logoPdf from "../../Img/icon_pdf.png";
import logoWord from "../../Img/icon.doc.png";
import logoExcel from "../../Img/icon_excel.png";

function DocumentsCallOut(props) {
  function documents(codi, nom) {
    Axios.get(`/arxiusStreaming`, {
      params: {
        id: codi,
      },
      responseType: "arraybuffer",
    })
      .then(function (response) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", nom);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch(function (error) {});
  }

  const tipusArxiu = (mime) => {
    if (mime?.includes("pdf")) {
      return logoPdf;
    } else if (mime?.includes("excel")) {
      return logoExcel;
    } else {
      return logoWord;
    }
  };

  return (
    <div className="callout">
      {props.infoDocuments?.map((document, index) => {
        let logo = tipusArxiu(document.mime);
        return (
          <div className="linkfitxa mt-2" key={index}>
            <button
              className="linkDocument pt-2"
              onClick={() => documents(document.codiArxiu, document.nomArxiu)}
            >
              <img
                src={logo}
                className="me-2 mb-2"
                width="24px"
                alt="tipusArxiu"
              />
              {document.titol}
            </button>
          </div>
        );
      })}
    </div>
  );
}

export default DocumentsCallOut;
